import React, { useState, useEffect, useRef } from 'react'
import { WithTooltip, Tooltip, Icon, Button, Tag } from '@abb/abb-common-ux-react'
import { getUserProfile } from 'slices/userProfileSlice'
import { useSelector } from 'react-redux'
// import { CommonConfirm } from "components/LoongInCommon"
import { CommonIndex } from 'components/LoongInCommon'
import { FormEdit } from './FormEdit'
import moment from 'moment'

import header from './header-128.png'

import { useDispatch } from 'react-redux'
// import { ResponseResult } from "common/request"
import { t } from 'i18next'
import { deleteWechatTag, getWechatTags, getWechatUsers, pullWechatTags, pullWechatUsers } from 'common/apis/wechat'
import { useMountedState } from 'common/useMountedState'
import axios from 'axios'
import { NotificationType, showNotification } from 'components/Notification/notificationSlice'
import { getUsers } from 'slices/userSlice'
import { SearchUser } from './SearchUser'
import { DropdownButton, DropdownButtonOption } from 'components/DropdownButton'

export default function Factories() {
  const userProfile = useSelector(getUserProfile)
  // const [updateUserRole, setUpdateUserRole] = useState(null)
  const [deletedItems, setDeletedItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [checkItems, setCheckItems] = useState([])
  const dispatch = useDispatch()
  const isMounted = useMountedState()
  const users = useSelector(getUsers)
  const searchUserRef = useRef(null)

  const refIndex = useRef(null)

  const scene = {
    ADD_SCENE_SEARCH: '公众号搜索',
    ADD_SCENE_ACCOUNT_MIGRATION: '公众号迁移',
    ADD_SCENE_PROFILE_CARD: '名片分享',
    ADD_SCENE_QR_CODE: '扫描二维码',
    ADD_SCENE_PROFILE_LINK: '图文页内名称点击',
    ADD_SCENE_PROFILE_ITEM: '图文页右上角菜单',
    ADD_SCENE_PAID: '支付后关注',
    ADD_SCENE_WECHAT_ADVERTISEMENT: '微信广告',
    ADD_SCENE_REPRINT: '他人转载',
    ADD_SCENE_LIVESTREAM: '视频号直播',
    ADD_SCENE_CHANNELS: '视频号',
    ADD_SCENE_WXA: '小程序关注',
    ADD_SCENE_OTHERS: '其他',
  }

  //列定义
  const columns = [
    {
      title: '昵称(未授权粉丝无法获得昵称)',
      key: 'nickName',
      render: (item, index) => {
        return (
          <>
            <img style={{ width: 32, height: 32, scale: 'revert', borderRadius: '50%', objectFit: 'contain' }} src={item.headimgUrl ?? header} />
            <span style={{ marginLeft: 8 }}>{item?.nickName}</span>
          </>
        )
      },
    },
    {
      title: '粉丝标签',
      key: 'tags',
      render: (item, index) => {
        return item.tags.map((t) => <Tag>{t.name}</Tag>)
      },
    },
    {
      title: '关联PowerCare用户',
      key: 'userId',
      render: (item, index) => {
        const manager = item.userId ? users.find((d) => d.id === item.userId) : null
        return manager?.name
      },
    },
    {
      title: '是否关注',
      key: 'subscribe',
      render: (item, index) => {
        return item.subscribe === 1 ? <Icon color='green' name='abb/check-mark'></Icon> : <Icon color='red' name='abb/close'></Icon>
      },
    },
    {
      title: '关注时间',
      key: 'subscribeTime',
      render: (item, index) => {
        return item.subscribe === 1 ? moment(item.subscribeTime * 1000).format('yyyy-MM-DD HH:mm:ss') : '未关注'
      },
    },
    {
      title: '渠道来源',
      key: 'subscribeScene',
      render: (item, index) => {
        return scene[item.subscribeScene]
      },
    },
    {
      title: '粉丝备注',
      key: 'remark',
    },
  ]

  const pullTags = () => {
    setIsLoading(true)
    axios
      .post(pullWechatUsers())
      .then((e) => {
        dispatch(showNotification(NotificationType.Success, '同步微信粉丝成功'))
        if (!isMounted()) {
          return
        }
        refIndex.current.query()
      })
      .catch((e) => {
        dispatch(
          showNotification(NotificationType.Alarm, e && e.error && e.error.message ? e.error.message : '同步微信粉丝失败，请稍后重试或联系管理员！')
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const setTags = (action) => {
    searchUserRef.current.showDialog(action, checkItems)
  }
  const onSelectionChange = (items) => {
    // console.log(items)
    setCheckItems(items)
  }
  //删除时放入删除项
  const onDeleteData = (deleteId) => {
    setDeletedItems([...deletedItems, deleteId])
  }
  const toolChildren = (
    <>
      <DropdownButton disabled={!checkItems || checkItems.length <= 0} title={'批量设置标签'} icon={'abb/label'}>
        <Button style={{ width: '100%' }} text='设置标签' isLoading={isLoading} type='ghost' sizeClass='small' onClick={() => setTags('bind')} />
        <Button text='移除标签' style={{ width: '100%' }} type='ghost' sizeClass='small' onClick={() => setTags('un-bind')} />{' '}
      </DropdownButton>
      <Button style={{ marginLeft: 8 }} text='同步' isLoading={isLoading} type='normal' sizeClass='small' icon={'abb/download'} onClick={pullTags} />
    </>
  )

  //返回页面
  return (
    <>
      <CommonIndex
        ref={refIndex}
        columns={columns}
        isLoading={isLoading}
        canSelection={true}
        toolWidth={280}
        getList={getWechatUsers}
        toolChildren={toolChildren}
        FormEdit={FormEdit}
        canAdd={false}
        canEdit={false}
        canDelete={false}
        onDeleteData={onDeleteData}
        onSelectionChange={onSelectionChange}
        enableSorting={true}
      />
      <SearchUser ref={searchUserRef} role='action' onRefresh={() => setTimeout(() => refIndex.current.query(), 500)} />
    </>
  )
}
