import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo } from 'react'
import { Checkbox } from '@abb/abb-common-ux-react'
import { CommonFormEdit } from 'components/LoongInCommon'
import { CommonRule } from 'components/LoongInCommon'
import { fetchGetAllRoleAsync, getAllRoles } from 'slices/roleSlice'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { createStation, getCustomerUsers, updateStation } from 'common/apis/maindata'
import { t } from 'i18next'
import { getClientHeight } from 'slices/appSlice'

export const StationFormEdit = forwardRef(({ id, customerId, formData, onSubmit, onCancel, event }, ref) => {
  //引用基础窗体控件
  const formEditRef = useRef(null)
  //获取客户区高度
  const clientHeight = useSelector(getClientHeight)
  const [roles, setRoles] = useState([])
  const allRoles = useSelector(getAllRoles)
  const [rules, setRules] = useState({})
  const [users, setUsers] = useState([])
  // const [formItem, setFormItem] = useState([]);

  const init = () => {
    axios.get(getCustomerUsers(), { params: { customerId:customerId } }).then((resp) => {
      const datas = resp.data
      setUsers(datas)
    })
  }
  useEffect(() => {
    init()
  }, [customerId])
  //初始化数据
  const initData = {
    id: '',
    stationNo: '',
    name: '',
    type: 0,
    customerId: '',
    address: '',
    manager: null,
    parentId: null,
    parentName: '',
  }
  //表单数据项
  const formItem = [
    {
      type: 'text',
      dataType: 'text',
      label: '上级变电站',
      fieldName: 'parentName',
      visible: !formData || !!formData.parentName,
      disabled: true,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('station.stationNo'),
      fieldName: 'stationNo',
      maxLength: 10,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('station.name'),
      fieldName: 'name',
      maxLength: 20,
    },
    {
      type: 'dropdown',
      label: t('station.type'),
      fieldName: 'type',
      options: [
        { label: '子级可添加变电站', value: 1 },
        { label: '子级可添加设备', value: 2 },
      ],
      maxLength: 256,
      disabled: id,
    },
    {
      type: 'dropdown',
      label: t('station.manager'),
      searchable: true,
      fieldName: 'manager',
      options: users.map(u=>({label:u.name,value:u.id})),
      maxLength: 256,
    },
    {
      type: 'dropdown',
      label: '关联用户',
      searchable: true,
      multiselect:true,
      showClearIcon: true,
      fieldName: 'users',
      
      options: users.map(u=>({label:u.name,value:u.id})),
      maxLength: 256,
    },
    {
      type: 'text',
      dataType: 'text',
      label: t('station.address'),
      fieldName: 'address',
      maxLength: 1000,
    },
  ]
  //表单的校验规则
  useEffect(() => {
    const newrules = {
      name: [CommonRule.Require],
      type: [CommonRule.Require],
    }
    setRules(newrules)
  }, [id])
  //获取更新时的参数
  const getUpdateInput = (inputdata) => {
    console.log(inputdata)
    return {
      id: inputdata.id,
      stationNo: inputdata.stationNo,
      name: inputdata.name,
      customerId: inputdata.customerId,
      address: inputdata.address,
      manager: inputdata.manager,
      users: inputdata.users,
    }
  }
  //获取添加时的参数
  const getCreateInput = (inputdata) => {
    return {
      stationNo: inputdata.stationNo,
      name: inputdata.name,
      type: inputdata.type,
      customerId: inputdata.customerId,
      address: inputdata.address,
      manager: inputdata.manager,
      parentId: inputdata.parentId,
      users: inputdata.users,
    }
  }

  const resetForm = () => {
    return formEditRef.current.resetForm()
  }
  //传递引用方法
  useImperativeHandle(ref, () => ({
    setShowSidePanel,
    showAndSwitchTab,
    resetForm,
  }))
  //显示边栏
  const setShowSidePanel = (visible) => {
    formEditRef.current.setShowSidePanel(visible)
  }
  //显示边栏并切换tab
  const showAndSwitchTab = (tabIndex) => {
    formEditRef.current.showAndSwitchTab(tabIndex)
  }
  //返回页面
  return (
    <CommonFormEdit
      ref={formEditRef}
      title={t('station.formTitle')}
      formItem={formItem}
      initData={initData}
      rules={rules}
      createData={createStation}
      updateData={updateStation}
      getUpdateInput={getUpdateInput}
      getCreateInput={getCreateInput}
      id={id}
      formData={formData}
      onSubmit={onSubmit}
      onCancel={onCancel}
      height={clientHeight - 280}
      event={event}
    >
      {/* 其他tab采用div在此添加 */}
    </CommonFormEdit>
  )
})
