import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserProfile, getUserProfile, getIsAuthenticated } from 'slices/userProfileSlice'
import { AbbBar, AppHeader, AppHeaderSpacer, AppTopNaviDivider } from '@abb/abb-common-ux-react'
import decode from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import './header.scss'

import { getEnv } from 'slices/envSlice'
import AccountMenu from './AccountMenu'

import { useMsal } from '@azure/msal-react'
import { UserManager } from 'oidc-client'
import { usePermission } from 'hooks/usePermission'
import { useCurrentUser } from 'hooks/useCurrentUser'

export function Header() {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const _env = useSelector(getEnv)
  const oauth = useSelector((state) => state.oauth)
  const user = oauth?.accessToken ? decode(oauth?.accessToken) : null
  const userProfile = useSelector(getUserProfile)
  const isAuthed = useSelector(getIsAuthenticated)
  const hasAdminRole = usePermission('AbpIdentity') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasEzreportRole = usePermission('EzReport') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasWechatRole = usePermission('Wechat') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasMaindataRole = usePermission('MainData') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const hasRemoteSupportRole = usePermission('RemoteSupport') // (_env.solution === 'OnPremise' && user?.role === 'admin') || userProfile?.visibilityCode === 'Global'
  const currentUser = useCurrentUser()
  const msal = useMsal()
  const { t } = useTranslation()
  // const [show,setShow] = useState(false)

  useEffect(() => {
    const userInfo = currentUser.getUser()
    dispatch(updateUserProfile(userInfo))
  }, [])

  // useEffect(() => {
  //   if (userProfile?.id) {
  //     dispatch(fetchUserSettingAsync(userProfile.id))
  //   }
  // }, [userProfile?.id])

  useEffect(() => {
    // console.log('isAuthed:', isAuthed)
    if (isAuthed !== null && isAuthed === false) {
      console.log('Not authorized on the API server')
      signOut(_env)
    }
  }, [isAuthed])

  const signOut = (e) => {
    if (e.authType === 'AzureB2C' && msal?.instance) {
      currentUser.clear()
      msal.instance.logoutRedirect()
    } else {
      currentUser.clear()
    }
  }

  if (userProfile?.currentUser !== null && userProfile?.currentUser !== undefined) {
    return null
  }
  // console.log(location.pathname)
  // console.log(userProfile)
  //TODO 这里需要添加菜单
  return (
    <div className='header-container'>
      <AbbBar productName={t('title')} />
      <AppHeader>
        <AppHeaderSpacer />

        {userProfile && (
          <>
            <div className='header-nav hideclass-ms hideclass-md'>
              {hasRemoteSupportRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/remoteSupport' || location.pathname.indexOf('/remoteSupport/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/remoteSupport')}>
                      {t('remoteSupport')}
                    </div>
                  </div>
                </>
              )}
              {hasEzreportRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/ezreport' || location.pathname.indexOf('/ezreport/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/ezreport')}>
                      {t('ezreport')}
                    </div>
                  </div>
                </>
              )}
              {/* {hasEzreportRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/wfreport' || location.pathname.indexOf('/wfreport/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/wfreport')}>
                      {t('wfreport')}
                    </div>
                  </div>
                </>
              )} */}
              {hasEzreportRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/inspection' || location.pathname.indexOf('/inspection/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/inspection')}>
                      {t('inspection')}
                    </div>
                  </div>
                </>
              )}
              {/* {hasEzreportRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/evaluation' || location.pathname.indexOf('/evaluation/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/evaluation')}>
                      {t('evaluation')}
                    </div>
                  </div>
                </>
              )} */}
              {hasMaindataRole && (
                <>
                  <div
                    className={`header-nav-item${
                      location.pathname === '/maindata' || location.pathname.indexOf('/maindata/') === 0 ? ' active' : ''
                    }`}
                  >
                    <div className='nav-button' onClick={() => history.push('/maindata')}>
                      {t('maindata')}
                    </div>
                  </div>
                </>
              )}
              {hasWechatRole && (
                <div className={`header-nav-item${location.pathname === '/wechat' || location.pathname.indexOf('/wechat/') === 0 ? ' active' : ''}`}>
                  <div className='nav-button' onClick={() => history.push('/wechat')}>
                    {t('wechat')}
                  </div>
                </div>
              )}
              {hasAdminRole && (
                <div
                  className={`header-nav-item${
                    location.pathname === '/' || location.pathname === '/admin' || location.pathname.indexOf('/admin/') === 0 ? ' active' : ''
                  }`}
                >
                  <div className='nav-button' onClick={() => history.push('/admin')}>
                    {t('admin')}
                  </div>
                </div>
              )}
            </div>
            <AppTopNaviDivider />
            <AccountMenu />
          </>
        )}
      </AppHeader>
    </div>
  )
}
