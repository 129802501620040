import React, { useEffect, useState } from 'react'
import { forwardRef } from 'react'
import '../../../styles/singleReport.scss'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { chunk, groupBy } from 'lodash'
import { Image } from 'components/Image'
import { LoadingIndicator } from '@abb/abb-common-ux-react'
import { useDataDictionary } from 'hooks/useDataDictionary'
export const SingleReport = forwardRef(({ report }, ref) => {
  const [testProblems, setTestProblems] = useState([])
  const types = useDataDictionary('DeviceType')

  const ranks = [
    { key: 1, title: '紧急' },
    { key: 2, title: '重大' },
    { key: 3, title: '一般' },
  ]
  const singleStatus = [
    { key: -2, title: '已退回' },
    { key: -1, title: '已驳回' },
    { key: 0, title: '待编辑' },
    { key: 1, title: '待审核' },
    { key: 2, title: '待生成' },
    { key: 3, title: '生成中' },
    { key: 4, title: '已生成' },
    { key: 5, title: '等待生成' },
  ]
  const singleStatusText = [
    { key: -2, title: '退回意见' },
    { key: -1, title: '驳回意见' },
  ]
  let index = 0

  const renderCheckItem = (item) => {
    const points = item.points.filter((v) => v.result?.code !== '3')
    return points.map((v, i, a) => {
      return (
        <tr key={nanoid()}>
          <td>{++index}</td>
          {i === 0 && <td rowSpan={points.length}>{item.name}</td>}
          <td>{v.name}</td>
          <td className='noborder-right center'>
            <input type='checkbox' checked={(v.result?.code ?? '1') === '1'} disabled={true} />
          </td>
          <td className='center'>
            <input type='checkbox' checked={v.result?.code === '2'} disabled={true} />
          </td>
          <td className='noborder-right center'>
            <input type='checkbox' checked={v.result?.code === '2' && v.result?.result === 1} disabled={true} />
          </td>
          <td className='center'>
            <input type='checkbox' checked={v.result?.code === '2' && v.result?.result === 2} disabled={true} />
          </td>
          <td>{v.result?.code === '2' ? v.result?.description : ''}</td>
        </tr>
      )
    })
  }

  const renderTestItem = (test, item) => {
    const modal = JSON.parse(item.modalString.replaceAll('rowspan', 'rowSpan').replaceAll('colspan', 'colSpan'))
    const r = JSON.parse(item.result)
    const result = { ...r?.result, ...item }
    if (r?.refuse) {
      return null
    }
    return (
      <table key={nanoid()} className='center '>
        {renderTestModal(modal.content, result)}
      </table>
    )
  }

  useEffect(() => {
    if (report && report.components) {
      const ps = report.components
        .sort((a, b) => {
          if (a.type.toUpperCase() < b.type.toUpperCase()) {
            return -1
          }
          if (a.type.toUpperCase() > b.type.toUpperCase()) {
            return 1
          }

          // name 必须相等
          return 0
        })
        .flatMap((i) => i.result)
        .flatMap((item) => item.testItems)
        .flatMap((test) => {
          return test.modals.flatMap((m) => {
            const r = JSON.parse(m.result)
            const props = r?.props
            const values = Object.values(props ?? {})
            return values.map((v) => {
              return { ...v, name: test.name }
            })
          })
        })
      setTestProblems(ps)
    }
  }, [report])

  const renderTestModal = (modal, result) => {
    return (
      modal &&
      modal.map((m) => {
        if (m.type === 'tr') {
          return (
            <tr {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tr>
          )
        }
        if (m.type === 'thead') {
          return (
            <thead {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </thead>
          )
        }
        if (m.type === 'tbody') {
          return (
            <tbody {...m.attrs} key={nanoid()}>
              {renderTestModal(m.componments, result)}
            </tbody>
          )
        }
        if (m.type === 'td') {
          return (
            <td {...m.attrs} key={nanoid()}>
              {m.label}
              {renderTestModal(m.componments, result)}
            </td>
          )
        }
        if (m.type === 'option') {
          return <input type='checkbox' key={nanoid()} checked={result && result[m.name] == m.key} disabled={true} />
        }
        if (m.type === 'editonly') {
          return ''
        }
        if (m.type === 'input') {
          const data = result[m.name]
          let value = data
          if (m.options) {
            value = m.options.find((o) => o.key == data)?.title
          }
          return <span key={nanoid()}>{m.label}{result && result[m.name] && (!!m.format ? m.format.replace('{value}', value) : value)}</span>
        }
        if (m.type === 'image') {
          return <Image key={nanoid()} id={result && result[m.name]} style={{ width: '95%' }} />
        }
        return renderTestModal(m.componments, result)
      })
    )
  }

  const renderDevice = (device, index) => {
    if (!(device && device.result)) {
      return null
    }
    const type = types?.details?.find((t) => t.code === device.type)
    return (
      <li key={nanoid()} className='canBeDeleted'>
        <h4>
          {type.displayText}
          {index}检修保养
        </h4>
        <ul data-order='1'>
          <li id='deviceinfo'>
            <h5>设备信息</h5>
            <table className='noborder-bottom'>
              <tbody>
                <React.Fragment key={nanoid()}>
                  <tr key={nanoid()} className={index === 0 ? 'border-top-solid' : ''}>
                    <td>
                      {type.displayText}
                      {device.parameters[0].title}：
                    </td>
                    <td>{device.parameters[0].value ?? 'NA'}</td>
                    <td>
                      {type.displayText}
                      {device.parameters[1].title}：
                    </td>
                    <td>{device.parameters[1].value ?? 'NA'}</td>
                    <td>
                      {type.displayText}
                      {device.parameters[2].title}：
                    </td>
                    <td>{device.parameters[2].value ?? 'NA'}</td>
                  </tr>
                  {chunk(
                    device.parameters.filter((d) => !(d.id === 'modal' || d.id === 'sn' || d.id === 'productDate')),
                    3
                  ).map((t) => (
                    <tr key={nanoid()}>
                      {t.map((d) => {
                        const kv = d
                        return (
                          <React.Fragment key={nanoid()}>
                            <td key={nanoid()}>{kv.title}</td>
                            <td key={nanoid()}>{kv.value ?? 'NA'}</td>
                          </React.Fragment>
                        )
                      })}
                      {t.length < 3 && (
                        <React.Fragment key={nanoid()}>
                          <td key={nanoid()}></td>
                          <td key={nanoid()}></td>
                        </React.Fragment>
                      )}
                      {t.length < 2 && (
                        <React.Fragment key={nanoid()}>
                          <td key={nanoid()}></td>
                          <td key={nanoid()}></td>
                        </React.Fragment>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              </tbody>
            </table>
          </li>
          {device.result.checkItems && device.result.checkItems.length > 0 && (
            <li className='canBeDeleted'>
              <h5>{type.displayText}检查及保养</h5>
              <table id='switchCheckTable'>
                <thead>
                  <tr className='border-top-solid border-right-dotted'>
                    <td rowSpan='2'>序号</td>
                    <td rowSpan='2'>检查项目</td>
                    <td rowSpan='2'>检查内容</td>
                    <td colSpan='2'>检查结果</td>
                    <td colSpan='2'>缺陷处理</td>
                    <td rowSpan='2'>缺陷处理原因</td>
                  </tr>
                  <tr>
                    <td>合格</td>
                    <td>不合格</td>
                    <td>已处理</td>
                    <td>未处理</td>
                  </tr>
                </thead>
                <tbody key={nanoid()}>{device.result.checkItems.map((i) => renderCheckItem(i))}</tbody>
              </table>
            </li>
          )}
          {device.result.testItems && device.result.testItems.length > 0 && (
            <li className='canBeDeleted'>
              <h5>{type.displayText}试验</h5>
              <ul data-order='2'>
                {device.result.testItems.map((test) => {
                  const r = JSON.parse(test.modals[0].result)
                  if (r?.refuse) {
                    return null
                  }
                  return (
                    <li key={nanoid()} id='expCircuitvoltage' className='li_exp'>
                      <h6>{test.name}</h6>
                      {test.modals.map((modal) => renderTestItem(test, modal))}
                    </li>
                  )
                })}
              </ul>
            </li>
          )}
        </ul>
      </li>
    )
  }

  return (
    <>
      <div className='single-report'>
        <div className='cover'>
          <h2 id='r_projectName'>{report?.project?.name}</h2>
          <h2 id='reportName'>
            {report?.components[0]?.name}
            维护保养单体报告
          </h2>
          <h2 id='cabinetNumber'>{report?.device?.displayName ?? 'HA01开关柜'}</h2>
          <div style={{ textAlign: 'left', flexDirection: 'row', display: 'flex' }}>
            <dl style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', flex: 1 }}>
              <dt>报告状态：</dt>
              <dd>{singleStatus.find((s) => s.key === report?.report?.status)?.title ?? '预览'}</dd>
              {report?.report?.status < 0 && (
                <>
                  <dt style={{ marginTop: 16 }}>{singleStatusText.find((s) => s.key === report?.report?.status)?.title}</dt>
                  <dd>{report?.report?.suggestion}</dd>
                </>
              )}
            </dl>
            <img src='/templates/SingleReportCover.png' alt='cover' />
          </div>
        </div>
        <ul className='content' data-order='0'>
          <li id='deviceinfo'>
            <h4>1. 设备信息</h4>
            <table className='noborder-bottom'>
              <tbody>
                {report &&
                  report.components &&
                  Object.values(
                    groupBy(
                      report.components.sort((a, b) => {
                        if (a.type.toUpperCase() < b.type.toUpperCase()) {
                          return -1
                        }
                        if (a.type.toUpperCase() > b.type.toUpperCase()) {
                          return 1
                        }

                        // name 必须相等
                        return 0
                      }),
                      (g) => g.type
                    )
                  ).map((devices) => {
                    console.log(devices)
                    return devices.map((device, index) => {
                      const type = types?.details?.find((t) => t.code === device.type)
                      return (
                        <React.Fragment key={nanoid()}>
                          <tr key={nanoid()}>
                            <td>
                              {type.displayText}
                              {devices.length > 1 && index + 1}
                              {device.parameters[0].title}：
                            </td>
                            <td>{device.parameters[0].value ?? 'NA'}</td>
                            <td>
                              {type.displayText}
                              {device.parameters[1].title}：
                            </td>
                            <td>{device.parameters[1].value ?? 'NA'}</td>
                            <td>
                              {type.displayText}
                              {device.parameters[2].title}：
                            </td>
                            <td>{device.parameters[2].value ?? 'NA'}</td>
                          </tr>
                          {chunk(
                            device.parameters.filter((d) => !(d.id === 'modal' || d.id === 'sn' || d.id === 'productDate')),
                            3
                          ).map((t) => (
                            <tr key={nanoid()}>
                              {t.map((d) => {
                                const kv = d
                                return (
                                  <React.Fragment key={nanoid()}>
                                    <td key={nanoid()}>{kv.title}</td>
                                    <td key={nanoid()}>{kv.value ?? 'NA'}</td>
                                  </React.Fragment>
                                )
                              })}
                              {t.length < 3 && (
                                <React.Fragment key={nanoid()}>
                                  <td key={nanoid()}></td>
                                  <td key={nanoid()}></td>
                                </React.Fragment>
                              )}
                              {t.length < 2 && (
                                <React.Fragment key={nanoid()}>
                                  <td key={nanoid()}></td>
                                  <td key={nanoid()}></td>
                                </React.Fragment>
                              )}
                            </tr>
                          ))}
                        </React.Fragment>
                      )
                    })
                  })}
              </tbody>
            </table>
          </li>
          {report &&
            report.components &&
            Object.values(
              groupBy(
                report.components.sort((a, b) => {
                  if (a.type.toUpperCase() < b.type.toUpperCase()) {
                    return -1
                  }
                  if (a.type.toUpperCase() > b.type.toUpperCase()) {
                    return 1
                  }

                  // name 必须相等
                  return 0
                }),
                (c) => c.type
              )
            ).map((devices) => {
              if (devices.length > 1) return devices.map((device, index) => renderDevice(device, index + 1))
              else return devices.map((device) => renderDevice(device))
            })}
          <li id='problemlist'>
            <h4>问题汇总</h4>
            <ul data-order='1'>
              <li>
                <h5>检查项缺陷汇总</h5>
                <table id='checkSummary'>
                  <thead>
                    <tr className='border-top-solid  border-right-dotted'>
                      <th>问题图片</th>
                      <th>处理后图片</th>
                      <th>问题等级</th>
                      <th>处理结果</th>
                      <th>问题描述/处理措施</th>
                    </tr>
                  </thead>
                  <tbody>
                    {report &&
                      report.components &&
                      report.components
                        .sort((a, b) => {
                          if (a.type.toUpperCase() < b.type.toUpperCase()) {
                            return -1
                          }
                          if (a.type.toUpperCase() > b.type.toUpperCase()) {
                            return 1
                          }

                          // name 必须相等
                          return 0
                        })
                        .flatMap((i) => i.result)
                        .flatMap((item) => item.checkItems)
                        .flatMap((item) => item.points)
                        .filter((i) => i.result?.code === '2')
                        .map((p, i) => {
                          const r = p.result
                          return (
                            <tr key={i}>
                              <td style={{ width: '17.5%' }}>
                                <Image id={r.beforPhoto} style={{ width: '100%' }} />
                              </td>
                              <td style={{ width: '17.5%' }}>{r.result === 1 && <Image id={r.afterPhtot} style={{ width: '100%' }} />}</td>
                              <td>{ranks.find((rk) => rk.key === r.rank)?.title ?? '未知'}</td>
                              <td>{r.result === 1 ? '已处理' : '未处理'}</td>
                              <td>{r.description}</td>
                            </tr>
                          )
                        })}
                  </tbody>
                </table>
              </li>
              <li>
                <h5>试验项缺陷汇总</h5>
                <table id='expSummary'>
                  <thead>
                    <tr className='border-top-solid  border-right-dotted'>
                      <th>试验项目</th>
                      <th>缺陷等级</th>
                      <th>处理结果</th>
                      <th>缺陷描述</th>
                      <th>处理措施</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testProblems &&
                      testProblems.map((p, i) => {
                        return (
                          <tr key={i}>
                            <td style={{ width: '35%' }}>{p.name}</td>
                            <td>{ranks.find((rk) => rk.key === p.rank)?.title ?? '未知'}</td>
                            <td>{p.result === 1 ? '已处理' : '未处理'}</td>
                            <td>{p.description}</td>
                            <td>{p.measures}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  )
})
