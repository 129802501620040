import React, { useState, useEffect, createContext, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ResponseResult } from 'common/request'
import { useDispatch, useSelector } from 'react-redux'
import { showNotification, NotificationType } from 'components/Notification/notificationSlice'
import SidePanel from 'components/SidePanel'
import { copyDevice, deleteDevice, deleteStation, getCustomerTree, uploadDrawing } from 'common/apis/maindata'
import { CommonConfirm } from 'components/LoongInCommon'

import {
  LoadingIndicator,
  Dropdown,
  DropdownOption,
  Spinner,
  Icon,
  Dialog,
  Checkbox,
  ThreeStateValue,
  Button,
  Link,
  WithTooltip,
  Tooltip,
} from '@abb/abb-common-ux-react'
import { StationFormEdit } from './StationFormEdit'
import { DeviceFormEdit } from './DeviceFormEdit'
import { StationSld } from './StationSld'
import defaultLogo from 'assets/ABB_logo_dark.svg'

import axios from 'axios'
import { getEnv } from 'slices/envSlice'
import { useMountedState } from 'common/useMountedState'
import { useRef } from 'react'
import { tr } from 'date-fns/locale'
import { t } from 'i18next'
import { set } from 'lodash'
import { useCurrentTenant } from 'hooks/useCurrentTenant'
import { DeviceFormCopy } from './DeviceFormCopy'
import { FileSelector } from 'components/FileSelector'
export const Details = ({ gateway, setGateway }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const urlParams = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [StationFormData, setStationFormData] = useState(null)
  const [stationDataIndex, setStationDataIndex] = useState('')
  const [stationDeleteId, setStationDeleteId] = useState(null)
  const [isStationDeleting, setIsStationDeleting] = useState(false)
  const [DeviceFormData, setDeviceFormData] = useState(null)
  const [deviceDataIndex, setDeviceDataIndex] = useState('')
  const [deviceDeleteId, setDeviceDeleteId] = useState(null)
  const [DeviceCopyFormData, setDeviceCopy] = useState(null)
  const [isDeviceDeleting, setIsDeviceDeleting] = useState(false)
  const [deviceShowId, setDeviceShowId] = useState(null)
  const [currentStation, setCurrentStation] = useState(null)
  const [errorText, setErrorText] = useState(null)
  const [client, setClient] = useState(null)
  const fromStationRef = useRef(null)
  const fromDeviceRef = useRef(null)
  const copyDeviceRef = useRef(null)
  const stationSldRef = useRef(null)
  const isMounted = useMountedState()
  const currentTenant = useCurrentTenant()
  const clientId = urlParams.id || currentTenant?.id
  const [callback, setCallback] = useState(null)
  const getClientTreeById = (clientId) => {
    setIsLoading(true)
    setClient(null)
    axios
      .post(getCustomerTree(clientId))
      .then((resp) => {
        if (!isMounted()) {
          return
        }
        setClient(resp.data)
      })
      .catch((e) => {
        if (!isMounted()) {
          return
        } else {
          if (e.response.status == 403) {
            dispatch(showNotification(NotificationType.Alarm, t('t_403Error')))
            setErrorText(t('t_403Error'))
          } else {
            dispatch(showNotification(NotificationType.Alarm, e.message))
            setErrorText(e.message)
          }
        }
      })
      .then((e) => setIsLoading(false))
  }

  useEffect(() => {
    if (clientId) {
      getClientTreeById(clientId)
    }
  }, [clientId])

  const onStationDeleteConfirm = async () => {
    try {
      setIsStationDeleting(true)
      const response = await axios.post(deleteStation(clientId), { id: stationDeleteId })
      if (response.status === 200 || response.status === 204) {
        setStationDataIndex('')
        setStationDeleteId(null)
        getClientTreeById(clientId)
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsStationDeleting(false)
    }
  }

  const onDeviceDeleteConfirm = async () => {
    try {
      setIsDeviceDeleting(true)
      const response = await axios.post(deleteDevice(clientId), { id: deviceDeleteId })
      if (response.status === 200 || response.status === 204) {
        setDeviceDataIndex('')
        setDeviceDeleteId(null)
        getClientTreeById(clientId)
        dispatch(showNotification(NotificationType.Success, t('t_hasDelete')))
      } else {
        dispatch(showNotification(NotificationType.Warn, t('t_deleteFail')))
      }
    } catch (e) {
      dispatch(showNotification(NotificationType.Alarm, t('t_deleteFail')))
    } finally {
      setIsDeviceDeleting(false)
    }
  }

  const onSubmit = (d) => {
    setStationDataIndex('')
    setStationFormData(null)
    getClientTreeById(clientId)
  }

  const onDeviceSubmit = (d) => {
    if (d.action === 'create') {
      callback && callback.c(d.data)
      setDeviceShowId(d.data.id)
    } else {
      setDeviceDataIndex('')
      setDeviceFormData(null)
      callback && callback.c(d.item)
    }
  }

  const onDeviceCopySubmit = (d) => {
    console.log(d)
    callback && callback.c(d.data)
    setDeviceShowId(d.data.id)
  }

  const onCancel = (d) => {
    setStationDataIndex('')
    setStationFormData(null)
    setDeviceDataIndex('')
    setDeviceFormData(null)
  }

  const showDevice = (show) => {
    if (show) {
      window.open(`/maindata/device/${deviceShowId}`)
    } else {
      setDeviceDataIndex('')
      setDeviceShowId(null)
      setDeviceFormData(null)
      //getClientTreeById(clientId)
    }
  }
  // Render
  if (!clientId) {
    return <div className='central-position text-gray'>请选择客户</div>
  } else if (isLoading) {
    return (
      <div className='central-position'>
        <LoadingIndicator type='radial' color='blue' sizeClass='small' />
      </div>
    )
  } else if (errorText) {
    return <div className='central-position text-gray'>{errorText}</div>
  }
  return (
    <>
      <div className='client-tree-container'>
        <PlantNodes
          showOnly={currentTenant.isTenant}
          client={client}
          onShowPanelCreatePlant={() => {
            setStationFormData({
              customerId: clientId,
            })
            setStationDataIndex(null)
            fromStationRef.current.setShowSidePanel(true)
          }}
          onShowPanelCreateSubstation={(p) => {
            const newItem = {
              customerId: clientId,
              ...p,
            }
            setStationFormData(newItem)
            setStationDataIndex(null)
            fromStationRef.current.setShowSidePanel(true)
          }}
          onShowPanelEditSubstation={(p) => {
            setStationFormData({
              customerId: clientId,
              ...p,
            })
            setStationDataIndex(p.key)
            fromStationRef.current.setShowSidePanel(true)
          }}
          onDeleteSubstation={(item) => {
            setStationDeleteId(item.key)
          }}
          onShowPanelCreateDevice={(p, cb) => {
            const newItem = {
              customerId: clientId,
              ...p,
            }
            setCallback({ c: cb })
            setDeviceFormData(newItem)
            setDeviceDataIndex(null)
            fromDeviceRef.current.setShowSidePanel(true)
          }}
          onShowPanelEditDevice={(p, cb) => {
            setDeviceFormData({
              customerId: clientId,
              ...p,
            })
            setCallback({ c: cb })
            setDeviceDataIndex(p.key)
            fromDeviceRef.current.setShowSidePanel(true)
          }}
          onDeleteDevice={(item) => {
            setDeviceDeleteId(item.key)
          }}
          onCopyDevice={(item, cb) => {
            setDeviceCopy({
              customerId: clientId,
              ...item,
            })
            setCallback({ c: cb })
            console.log(cb)
            setDeviceDataIndex(item.key)
            copyDeviceRef.current.setShowSidePanel(true)
          }}
          onShowDevice={(item) => {
            window.open(`/maindata/device/${item.key}`)
          }}
          onShowSubstationSld={(p) => {
            setCurrentStation(p)
            stationSldRef.current.setShowSidePanel(true)
          }}
        />
      </div>
      {!currentTenant.isTenant && (
        <>
          <StationFormEdit ref={fromStationRef} onSubmit={onSubmit} onCancel={onCancel} customerId={StationFormData?.customerId} formData={StationFormData} id={stationDataIndex} />
          <DeviceFormEdit ref={fromDeviceRef} onSubmit={onDeviceSubmit} onCancel={onCancel} formData={DeviceFormData} id={deviceDataIndex} />
          <DeviceFormCopy ref={copyDeviceRef} onSubmit={onDeviceCopySubmit} onCancel={onCancel} formData={DeviceCopyFormData} id={deviceDataIndex} />
          <StationSld ref={stationSldRef} substation={currentStation} onSeved={onSubmit} />
          <CommonConfirm
            title={t('t_titleDeleteData')}
            content={t('t_confirmDeleteData')}
            isVisible={stationDeleteId != null}
            onConfirm={onStationDeleteConfirm}
            confirmText={t('b_confirm')}
            onCancel={() => setStationDeleteId(null)}
            isLoading={isStationDeleting}
          />
          <CommonConfirm
            title={t('t_titleDeleteData')}
            content={t('t_confirmDeleteData')}
            isVisible={deviceDeleteId != null}
            onConfirm={onDeviceDeleteConfirm}
            confirmText={t('b_confirm')}
            onCancel={() => setDeviceDeleteId(null)}
            isLoading={isDeviceDeleting}
          />
          <CommonConfirm
            title='设备信息'
            content='您已创建了设备，是否现在添加设备相关信息？'
            isVisible={deviceShowId != null}
            onConfirm={() => showDevice(true)}
            confirmText={t('b_confirm')}
            onCancel={() => showDevice(false)}
          />
        </>
      )}
    </>
  )
}

const PlantNodes = ({
  client,
  showOnly,
  onShowPanelCreatePlant,
  onDeleteSubstation,
  onShowPanelCreateSubstation,
  onShowPanelEditSubstation,
  onShowSubstationSld,
  onShowPanelCreateDevice,
  onShowPanelEditDevice,
  onDeleteDevice,
  onCopyDevice,
  onShowDevice,
}) => {
  const env = useSelector(getEnv)
  const history = useHistory()
  const dispatch = useDispatch()
  const [expandItem, setExpandItem] = useState(null)
  const onUploaded = (files) => {
    console.log(files)
    dispatch(showNotification(NotificationType.Success, '上传图纸成功'))
  }
  return (
    <>
      <ul className='tree-level-plant'>
        {!showOnly && (
          <li className='node-plant'>
            <div className='box-plant btn-add' onClick={onShowPanelCreatePlant}>
              添加变电站
            </div>
          </li>
        )}
        {client?.children?.map((plant) => (
          <li className='node-plant' key={plant.key}>
            <div className={`box-plant${plant.key === expandItem ? '' : ' collapsed'}`} onClick={() => setExpandItem(plant.key)}>
              <div className='box-body'>
                <div>{plant.name}</div>
                <div>
                  <small className='text-gray'>{plant?.extensionData?.address}</small>
                </div>
              </div>
              {!showOnly && (
                <div className='node-controls'>
                  <WithTooltip>
                    <Link
                      discreet
                      underlined={false}
                      sizeClass='small'
                      iconBefore='abb/trash'
                      onClick={(e) => {
                        onDeleteSubstation(plant)
                        e.stopPropagation()
                      }}
                    />
                    <Tooltip>
                      <div>删除变电站</div>
                    </Tooltip>
                  </WithTooltip>
                  <WithTooltip>
                    <Link
                      discreet
                      underlined={false}
                      sizeClass='small'
                      iconBefore='abb/edit'
                      onClick={(e) => {
                        onShowPanelEditSubstation(plant)
                        e.stopPropagation()
                      }}
                    />
                    <Tooltip>
                      <div>编辑</div>
                    </Tooltip>
                  </WithTooltip>
                  {plant.type === 2 && (
                    <>
                      <FileSelector uploadUrl={uploadDrawing()} params={{ stationId: plant.id }} showDownload={false} onValueChanged={onUploaded}>
                        <WithTooltip>
                          <Link discreet underlined={false} sizeClass='small' iconBefore='abb/document' />
                          <Tooltip>
                            <div>上传站点图纸</div>
                          </Tooltip>
                        </WithTooltip>
                      </FileSelector>
                      <WithTooltip>
                        <Link
                          discreet
                          underlined={false}
                          sizeClass='small'
                          iconBefore='abb/sld-2'
                          onClick={(e) => {
                            onShowSubstationSld(plant)
                            e.stopPropagation()
                          }}
                        />
                        <Tooltip>
                          <div>配置单线图</div>
                        </Tooltip>
                      </WithTooltip>
                    </>
                  )}
                </div>
              )}
            </div>
            {plant.children && plant.type === 1 && (
              <SubstationNodes
                showOnly={showOnly}
                substations={plant.children}
                parent={plant}
                isExpand={plant.key === expandItem}
                onShowPanelCreateSubstation={onShowPanelCreateSubstation}
                onShowPanelEditSubstation={onShowPanelEditSubstation}
                onDeleteSubstation={onDeleteSubstation}
                onShowPanelCreateDevice={onShowPanelCreateDevice}
                onShowPanelEditDevice={onShowPanelEditDevice}
                onDeleteDevice={onDeleteDevice}
                onCopyDevice={onCopyDevice}
                onShowDevice={onShowDevice}
                onShowSubstationSld={onShowSubstationSld}
              />
            )}
            {plant.children && plant.type === 2 && (
              <DeviceNodes
                showOnly={showOnly}
                isExpand={plant.key === expandItem}
                devices={plant.children}
                station={plant}
                onShowPanelCreateDevice={onShowPanelCreateDevice}
                onShowPanelEditDevice={onShowPanelEditDevice}
                onDeleteDevice={onDeleteDevice}
                onCopyDevice={onCopyDevice}
                onShowDevice={onShowDevice}
              />
            )}
          </li>
        ))}
      </ul>
    </>
  )
}

const SubstationNodes = ({
  substations,
  isExpand,
  showOnly,
  parent,
  onShowPanelCreateSubstation,
  onShowPanelEditSubstation,
  onShowSubstationSld,
  onDeleteSubstation,
  onShowPanelCreateDevice,
  onShowPanelEditDevice,
  onDeleteDevice,
  onCopyDevice,
  onShowDevice,
}) => {
  const env = useSelector(getEnv)
  const history = useHistory()
  const [expandItem, setExpandItem] = useState(null)
  const dispatch = useDispatch()
  const onUploaded = (files) => {
    console.log(files)
    dispatch(showNotification(NotificationType.Success, '上传图纸成功'))
  } 
  return (
    <ul className={`tree-level-substation${isExpand ? '' : ' collapsed'}`} style={{ display: isExpand ? '' : 'none' }}>
      {substations.map((substation) => (
        <li className='node-substation' key={substation.key}>
          <div className={`box-substation${substation.key === expandItem ? '' : ' collapsed'}`} onClick={() => setExpandItem(substation.key)}>
            <div className='box-body'>{substation.title}</div>
            {!showOnly && (
              <div className='node-controls'>
                <WithTooltip>
                  <Link
                    discreet
                    underlined={false}
                    sizeClass='small'
                    iconBefore='abb/trash'
                    onClick={(e) => {
                      onDeleteSubstation(substation)
                      e.stopPropagation()
                    }}
                  />
                  <Tooltip>
                    <div>删除变电站</div>
                  </Tooltip>
                </WithTooltip>
                <WithTooltip>
                  <Link
                    discreet
                    underlined={false}
                    sizeClass='small'
                    iconBefore='abb/edit'
                    onClick={(e) => {
                      onShowPanelEditSubstation(substation)
                      e.stopPropagation()
                    }}
                  />
                  <Tooltip>
                    <div>编辑</div>
                  </Tooltip>
                </WithTooltip>
                {substation.type === 2 && (
                  <>
                    <FileSelector uploadUrl={uploadDrawing()} params={{ stationId: substation.id }} showDownload={false} onValueChanged={onUploaded}>
                        <WithTooltip>
                          <Link discreet underlined={false} sizeClass='small' iconBefore='abb/document' />
                          <Tooltip>
                            <div>上传站点图纸</div>
                          </Tooltip>
                        </WithTooltip>
                      </FileSelector>
                    <WithTooltip>
                      <Link
                        discreet
                        underlined={false}
                        sizeClass='small'
                        iconBefore='abb/sld-2'
                        onClick={(e) => {
                          onShowSubstationSld(substation)
                          e.stopPropagation()
                        }}
                      />
                      <Tooltip>
                        <div>配置单线图</div>
                      </Tooltip>
                    </WithTooltip>
                  </>
                )}
              </div>
            )}
          </div>
          {substation.children && substation.type === 1 && (
            <SubstationNodes
              showOnly={showOnly}
              isExpand={substation.key === expandItem}
              substations={substation.children}
              onShowPanelCreateSubstation={onShowPanelCreateSubstation}
              parent={substation}
              onShowPanelEditSubstation={onShowPanelEditSubstation}
              onDeleteSubstation={onDeleteSubstation}
              onShowPanelCreateDevice={onShowPanelCreateDevice}
              onShowPanelEditDevice={onShowPanelEditDevice}
              onDeleteDevice={onDeleteDevice}
              onCopyDevice={onCopyDevice}
              onShowDevice={onShowDevice}
              onShowSubstationSld={onShowSubstationSld}
            />
          )}
          {substation.children && substation.type == 2 && (
            <DeviceNodes
              showOnly={showOnly}
              isExpand={substation.key === expandItem}
              devices={substation.children}
              station={substation}
              onShowPanelCreateDevice={onShowPanelCreateDevice}
              onShowPanelEditDevice={onShowPanelEditDevice}
              onDeleteDevice={onDeleteDevice}
              onCopyDevice={onCopyDevice}
              onShowDevice={onShowDevice}
            />
          )}
        </li>
      ))}
      {!showOnly && (
        <li className='node-substation'>
          <div
            className='box-substation btn-add'
            onClick={() => {
              onShowPanelCreateSubstation({ parentId: parent.key, parentName: parent.title })
            }}
          >
            添加变电站
          </div>
        </li>
      )}
    </ul>
  )
}

const DeviceNodes = ({
  devices,
  station,
  showOnly,
  parent,
  onShowPanelCreateDevice,
  onShowPanelEditDevice,
  onDeleteDevice,
  onCopyDevice,
  onShowDevice,
  isExpand,
}) => {
  const env = useSelector(getEnv)
  const history = useHistory()
  const [expandItem, setExpandItem] = useState(null)
  const [showedDevices, setShowedDevices] = useState([])
  const [showCount, setShowCount] = useState(0)
  useEffect(() => {
    if (isExpand) {
      setShowCount(10)
    }
  }, [isExpand])
  useEffect(() => {
    if (showCount > 0) {
      setShowedDevices(devices.slice(0, showCount))
    }
  }, [showCount])
  const addDevice = (d) => {
    console.log(d)
    const device = { ...d }
    devices.push(device)
    showedDevices.push(device)
  }
  const editDevice = (d) => {
    const old = devices.find((v) => v.key === d.id)
    if (!old) {
      return
    }
    const device = { ...old, ...d, title: d.displayName, name: d.displayName }
    devices[devices.findIndex((e) => e.key === d.id)] = device
    const sd = [...showedDevices]
    sd[sd.findIndex((e) => e.key === d.id)] = device
    console.log(sd)
    setShowedDevices(sd)
  }
  return (
    <ul className={`tree-level-substation${isExpand ? '' : ' collapsed'}`} style={{ display: isExpand ? '' : 'none' }}>
      {showedDevices.map((device) => (
        <li className='node-substation' key={device?.key}>
          <div className={`box-substation${device.key === expandItem ? '' : ' collapsed'}`} onClick={() => setExpandItem(device.key)}>
            <div className='box-body'>{device.title}</div>
            {!showOnly && (
              <div className='node-controls'>
                <WithTooltip>
                  <Link discreet sizeClass='small' iconBefore='abb/open-in-new-window' onClick={() => onShowDevice(device)} underlined={false} />
                  <Tooltip>
                    <div>打开设备</div>
                  </Tooltip>
                </WithTooltip>

                <WithTooltip>
                  <Link
                    discreet
                    underlined={false}
                    sizeClass='small'
                    iconBefore='abb/copy'
                    onClick={(e) => {
                      onCopyDevice({ stationId: station?.key, stationName: station?.title, ...device }, addDevice)
                      e.stopPropagation()
                    }}
                  />
                  <Tooltip>
                    <div>复制设备</div>
                  </Tooltip>
                </WithTooltip>

                <WithTooltip>
                  <Link
                    discreet
                    underlined={false}
                    sizeClass='small'
                    iconBefore='abb/trash'
                    onClick={(e) => {
                      onDeleteDevice(device)
                      e.stopPropagation()
                    }}
                  />
                  <Tooltip>
                    <div>删除设备</div>
                  </Tooltip>
                </WithTooltip>
                <WithTooltip>
                  <Link
                    discreet
                    underlined={false}
                    sizeClass='small'
                    iconBefore='abb/edit'
                    onClick={(e) => {
                      onShowPanelEditDevice({ stationId: station?.key, stationName: station?.title, ...device }, editDevice)
                      e.stopPropagation()
                    }}
                  />
                  <Tooltip>
                    <div>编辑</div>
                  </Tooltip>
                </WithTooltip>
              </div>
            )}
          </div>
          {device.children && (
            <DeviceNodes
              showOnly={showOnly}
              isExpand={device.key === expandItem}
              devices={device.children}
              parent={device}
              station={station}
              onShowPanelCreateDevice={onShowPanelCreateDevice}
              onShowPanelEditDevice={onShowPanelEditDevice}
              onDeleteDevice={onDeleteDevice}
              onCopyDevice={onCopyDevice}
              onShowDevice={onShowDevice}
            />
          )}
        </li>
      ))}
      {showCount < devices.length && (
        <li className='node-substation'>
          <div
            className='box-substation btn-add'
            onClick={() => {
              const count = Math.min(showCount + 10, devices.length)
              setShowCount(count)
            }}
          >
            显示更多
          </div>
        </li>
      )}
      {!showOnly && (
        <li className='node-substation'>
          <div
            className='box-substation btn-add'
            onClick={() =>
              onShowPanelCreateDevice(
                {
                  stationId: station?.key,
                  sn: parent?.sn,
                  orderNumber: parent?.orderNumber,
                  factoryId: parent?.factoryId,
                  productDate: parent?.productDate,
                  pannelNo: parent?.pannelNo,
                  pannelName: parent?.pannelName,
                  busNo: parent?.busNo,
                  stationName: station?.title,
                  parentId: parent?.key,
                  parentName: parent?.title,
                },
                addDevice
              )
            }
          >
            添加设备
          </div>
        </li>
      )}
    </ul>
  )
}
